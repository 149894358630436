@import '../../styles/mixins';

$largest-size: 1440px;
$large-size: 1280px;
$medium-size: 1024px;
$small-size: 768px;
$small-mid-size: 667px;
$smallest-size: 480px;
$ultra-smallest-size: 375px;

$transition-slow: 450ms ease;
$transition-base: 300ms ease;

$font-size-base: 16;
$font-family-base: 'Open Sans', sans-serif;
$font-family-solid: 'Montserrat', sans-serif;
$font-family-italic: 'Libre Baskerville', sans-serif;

$color-blue: #006CA9;
$color-orange: $color-blue;
$color-orange-dark: $color-blue;
$color-orange-1: #FF6B58;
//$color-orange-dark: #c94a24;

$color-laguna: #00A3AF;
$color-laguna-light: #F2FAFB;
$color-laguna-dark: #007178;
//$color-laguna-dark: #00ADB5;

$color-grey-light: #adadad;
$color-grey: #676767;

$primary-1: #ae0032;
$primary-2: #455162;
$primary-3: #1f2f46;

$neutral-1: #0d1720;
$neutral-2: #636363;
$neutral-3: #dadada;
$neutral-4: #f2f2f2;
$neutral-5: #fcfbfa;

.s-key-areas {
    position: relative;

    &::before {
        content: '';

        width: 100%;
        height: 108%;

        position: absolute;
        top: -8%;
        left: 0;
        z-index: -1;
        transform: skewY(-10deg);

        background-color: $color-laguna-light;

        @media (min-width: $smallest-size) {
            height: 110%;
            
            top: -10%;
        }
        @media (min-width: $medium-size) {
            height: 110%;

            top: -5%;
        }
        @media (min-width: $largest-size) {
            transform: skewY(-7deg);
        }

    }
}
.s-key-areas__container {
    padding: {
        top: 40px;
        bottom: 100px;
    };

    position: relative;
    overflow: hidden;

    @media (min-width: $smallest-size) {
        overflow: visible;
    }
    @media (min-width: $medium-size) {
        padding: {
            top: 120px;
            bottom: 100px;
        };
    }
}

.s-key-areas__wr-bg-img {
    width: 700px;

    @include img-wrap;

    position: absolute;
    bottom: 600px;
    left: 0;
    z-index: -1;
    transform: translateX(-50%);

    @include aspect-ratio-w-pseudo(1);

    @media (min-width: $medium-size) {
        bottom: 400px;
    }
    @media (min-width: $large-size) {
        width: 800px;

        bottom: 0;
        transform: translate(-40%, 15%);
    }

    img,
    svg {
        object-fit: contain;
    }
}

.s-key-areas__head {
    margin-bottom: 30px;

    @media (min-width: $small-mid-size) {
        margin-bottom: 40px;
    }
}
.s-key-areas__heading {
    display: flex;
    flex-wrap: wrap;

    margin-bottom: 20px;
}
.s-key-areas__heading-italic {
    margin: {
        left: 8px;
        right: 8px;
    };
}
.s-key-areas__head-text {
    max-width: 480px;
    
    @media (min-width: $small-size) {
        max-width: 670px;
    }
}

.s-key-areas__wr-list {
    @media (min-width: $large-size) {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-column-gap: 32px;
    }
    @media (min-width: $largest-size) {
        grid-template-columns: 3fr 1fr;
    }
}
.s-key-areas__list {
    display: grid;
    grid-row-gap: 30px;

    margin-bottom: 30px;

    @media (min-width: $small-mid-size) {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 32px;
        grid-row-gap: 40px;

        margin-bottom: 40px;
    }

    @media (min-width: $medium-size) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media (min-width: $large-size) {
        grid-template-columns: 1fr 1fr;

        margin-bottom: 0;
    }
    @media (min-width: $largest-size) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.bl-key-cta {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media (min-width: $small-mid-size) {
        flex-direction: row;
    }
    @media (min-width: $medium-size) {
        width: 67%;
    }
    @media (min-width: $large-size) {
        flex-direction: column;
        
        width: 100%;
        height: 100%;
    }
}
.bl-key-cta__wr-img {
    width: 100%;
    height: 200px;

    position: relative;

    img {
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;
        
        object-fit: cover;
        object-position: center;
    }

    @media (min-width: $small-mid-size) {
        width: 50%;
        height: 300px;
    }
    @media (min-width: $large-size) {
        width: 100%;
        height: 50%;
    }
}
.bl-key-cta__wr-text {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: {
        top: 40px;
        bottom: 40px;
    };

    font-size: 16px;
    color: #ffffff;
    text-align: center;

    background-color: $color-laguna;

    @media (min-width: $small-mid-size) {
        justify-content: center;

        width: 50%;
        padding: {
            top: 0;
            bottom: 0;
            left: 20px;
            right: 20px;
        };
    }
    @media (min-width: $large-size) {
        width: 100%;
        height: 50%;
    }
}
.bl-key-cta__text {
    max-width: 260px;
    margin-bottom: 20px;

    @media (min-width: $large-size) {
        max-width: 240px;
    }
}
.bl-key-cta__btn {
    color: $color-orange-dark;
}